.App-header{
  width: 100%; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: 100%;
  height: 100%;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  object-fit: cover;
}
.overlay {
  z-index:1;
  margin : 20px 40px;
  text-shadow: 2px 2px 4px #000000;
}

.overlay h1{
  font-size: 2rem;
}
.homeAboutDiv{
  background-color: #000000;
  width: 100%;
  height: 100%;
  padding : 0px;
  margin : 0px;
}

.aboutImg{
  padding : 20px;
}

.aboutText{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #eee;
  padding: 50px;
  text-shadow: 1px 1px 2px #000;
  
}

.aboutText h3{
  line-height: 3rem;
}
.myBtn{
  font-weight: 500;
  color: #fff;
  background-color:  #F49F1C;
  padding: 6px 10px;
  border: none;
}

.homeContactDiv{
  padding: 80px 0 30px 0;
}

.homeContactText{
  text-align: left;
  font-weight: 500;
  line-height: 1.39;
  letter-spacing: .1rem;
}

.darkHeading{
  margin-top: -10px;
}
.lightHeading{
  opacity: 0.3;
}
.lighterHeading{
  color:#eee;
  opacity: 0.5;
}
.whiteHeading{
  color: #eee;
}

.fitImage{
  width: 75%;
  height: auto;
  object-fit: contain;
}

.dronacharya-image{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.drone-img{
  /* height: auto; */
  z-index: 1;

  width: 120px;
  height: auto;
  margin-top: 30px;

  }