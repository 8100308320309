.button-animation{
  color: #333;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Unna:ital',serif;
  font-weight: bold;
  position: relative;
  margin-bottom: 60px;
  margin-top: 0;
  line-height: 42px;
  display: inline-flex;
  z-index: 1;
  margin: 20px 40px;
}

.button-animation::after{
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  margin: 0 auto 0;
  background: #F49F1C;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: -1;
  border-radius: 50%;
  justify-content: flex-end;
  transform: translate3d(-20px, 0, 0);
  transition: width .3s;
  -webkit-transition: width .3s;
  border-radius: 25px;
}

.button-animation:hover::after{
  width: calc(100% + 40px);
  transition: 0.2s;
  animation-delay: .3s;
  animation-iteration-count: 1;
  animation-duration: 1.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0, 1);
}

.button-animation> * {
  z-index: 100;
}

.orange::after{
  background: #F49F1C;
}
.blue::after{
  background: lightskyblue;
}

.white{
  color: #eee;
}

.black{
  color: #000;
}

