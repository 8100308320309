
.my-gallery-component{
    background-color: #000;
    margin-top: 85px;
}

.my-360-gallery-component{
    background-color: #000;
    margin-top: 160px;
}

.gallery-heading{
    text-shadow: 2px 2px 8px #000;
    padding: 40px 0;
    font-size: 2rem;
}

.pano360-container{
    margin: 0 10vw;
}

.fit-image{
    width: 100%;
    height: auto;
    object-fit: contain;
}

.vt-card{
    background-color: #eee;
    color: #000;
    padding-bottom : 5px;
}

.vt-text{
    
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
    padding-top: 20px;
}

.top-bar{
    width: 100%;
    background-color: #000;
    padding: 10px 10px; 
    margin-top: 85px;
    display:flex;
    position: fixed;
    z-index: 10;
}
.top-bar ul{
list-style-type: none;
  margin: 15px auto 15px auto;
  padding: 0;

}
.top-bar ul li {
    display: inline;
}
.top-bar ul li a{
    color: #fff;
    text-transform: uppercase;
    font-family: 'Unna:ital',serif;
    text-align: center;
    display: inline;
    font-size: 16px;
 
  text-align: center;
  padding: 10px;
  text-decoration: none;
}

.my-360-gallery-component,.my-gallery-component, .my-video-gallery-component  h2{
    color: #eee;
    padding: 20px;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.thumbnail-play{
    position: absolute;
    top: 45%;
    left: 45%;
    color: #fff;
}
.thumbnail-play:hover{
    color: #A9A9A9;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}
.iframeClass{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 20px;
}