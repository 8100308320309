.underlined-heading{
    color: #333;
    text-align: center;
    /* text-transform: uppercase; */
    /* font-family: 'Unna:ital',serif; */
    /* font-weight: bold; */
    position: relative;
    margin-bottom: 60px;
    margin-top: 0;
    z-index: 1;
    display: inline;
    font-size: 1em;
  }
  
  .underlined-heading::after{
    content: "";
    width: 100%;
    position: absolute;
    margin: 0 auto 0;
    height: 35%;
    background: #F49F1C;
    left: 0;
    right: 0;
    bottom: 2px;
    opacity: 1;
    z-index: -3;
  }
  
  .underlined-heading:hover::after{
    height: 100%;
    transition: 0.2s;
    animation-delay: .3s;
    animation-iteration-count: 1;
    animation-duration: 1.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }

  .blue::after{
    background-color: #87cefa;
  }

  .orange::after{
    background-color: #F49F1C;
  }

  .white{
    color: #eee;
  }

  .black{
    color: #000;
  }