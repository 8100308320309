.courses-component{
    margin-top: 85px;
}
.highlights-component{
    padding: 80px 0;
}

.highlights-div{
    padding: 0px 0 80px 0;
}

.highlights-text{
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 4px;
    position: relative;
    display: inline;
}

.highlights-text::before{
    content: "";
    width: 100%;
    position: absolute;
    margin: 0 auto 0;
    height: 4px;
    background: #87cefa;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index: -3;
}

.highlights-text::after{
    content: "";
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    margin: 0 auto 0;
    background: #87cefa;
    right: -8px;
    bottom: 0;
    opacity: 1;
    z-index: -3;
    transition: width .3s;
  -webkit-transition: width .3s;
}

.highlights-text:hover::after{
  width: calc(100% + 16px);
  transition: 0.2s;
  animation-delay: .3s;
  animation-iteration-count: 1;
  animation-duration: 1.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0, 1);
}

.highlights-num{
    position: relative;
    top: -2rem;
    right: -8px;
    font-weight: 800;
    letter-spacing: 4px;
    opacity: 0.5;
    font-size: 1.25rem;
}   

.heading{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
}


.pluto-vector{
    width: 100px;
    height: 100px;
    /* position: absolute;
     */

    animation: ScaleUpDown 3s linear infinite;
    position: absolute;
    top: 20%;
    left: 50%;
}


@keyframes ScaleUpDown {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5)
    }
  }

.course-overview-div ,.syllabus-div, .pre-requisities-div{
    padding-bottom: 150px;
}

.syllabus-div{
    position: relative;
    /* background-color: bisque; */

}
.courses-gallery-div{
    padding-top: 150px;
    display: block;
}
.courses-header-text{
    color: navy;
    text-shadow: 1px 1px 4px #fff !important;
}

.aerial-robotics-header{
    background-image: url(../images/webp/workshop-bg.webp);
    background-repeat:no-repeat;
    background-size: cover;
}

.bootcamp-header{
    background-image: url(../images/bootcamp-header.gif);
    background-repeat:no-repeat;
    background-size: cover;
}
.pre-requisites-list li{
    padding: 0.6rem;
    /* margin: 20px; */
}
.syllabus-header {
    /* position: sticky;
    top: 100px;
    transform: translateY(-50%); */
    position: sticky;
    position: -webkit-sticky;
    /* background: #f83d23; */
    /* width: 100px;
    height: 100px; */
    top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 0 6px #000;
    color: #fff; */
}

.syllabus-modules ul li{
    margin-bottom: 5rem;
    list-style: none;
    text-align: justify;
}
.syllabus-modules ul li h2{
    font-size: 1.4em;
}

.syllabus-section-drone{
    width: 106.66px;
    height: 80px;
    position: sticky;
    top: 50%;
    left: 50%;
    /* width: 50px;
    height: 50px; */
    /* border-radius: 50%;
    background-color: #ffceb0; */
    transform: translate(-50%, 0%);
    z-index: -2;
}
.pre-requisities-content{
    /* background-color: #bbb; */
    padding-top: 2rem;
    /* margin: 1rem 0 8rem 0; */
    /* border : 1px 1px 5px #87cefa */
}

.pre-requisities-content ul{
    padding: 0;
    margin: 0;
}
.pre-requisities-content ul li{
    margin-bottom: 2rem;
    list-style: none;
}

.bg-circle{
    position: sticky;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffceb0;
    transform: translate(-50%, -50%);
    z-index: -2;
    /* position: sticky; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    height: 50px; 
    width: 100px; 
    border-radius: 150px 150px 0 0; 
    background-color:#ffceb0; 
     */

}

.bg-drone{
    position: sticky;
    top: 54%;
    left: 50%;
    width: 50px;
    height: 50px;
    /* border-radius: 50%; */
    border-radius:50% 50% 0 0;
    transform: translate(-50%, -50%);
    z-index: -1;
    
}

.drone-build-image, .vr-vector-image,.survey-vector-image,.filming-vector-image{
    width: 80%;
}


@media only screen and (max-width: 600px) {
    .book-header{
        background-image: url(../images/book-hero-bg.png);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .aerial-robotics-header{
        background-image: url(../images/webp/workshop-bg.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }

    .bootcamp-header{
        background-image: url(../images/webp/neural-network-bg.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .survey-header{
        background-image: url(../images/webp/survey-plain-bg.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .road-survey-header{
        background-image: url(../images/webp/road-survey-plain-blue-bg.jpg);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .vt-header{
        background-image: url(../images/webp/Simple-White-Gradient-Background-Image.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .filming-header{
        /* background-image: url(../images/filming-course-header.gif);
        background-repeat:no-repeat;
        background-size: 100% 100%; */
        background-color: #a9d1e1;
    }
    

    .courses-header-text{
        color: navy;
        text-shadow: 1px 1px 4px #fff !important;
        text-align: center;
    }
}

@media only screen and (min-width: 600px) {
    .book-header{
        background-image: url(../images/book-hero-bg.png);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .aerial-robotics-header{
        background-image: url(../images/webp/workshop-bg.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .bootcamp-header{
        background-image: url(../images/webp/neural-network-bg.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .survey-header{
        background-image: url(../images/webp/survey-plain-bg.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .road-survey-header{
        background-image: url(../images/webp/road-survey-plain-blue-bg.jpg);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .vt-header{
        background-image: url(../images/webp/Simple-White-Gradient-Background-Image.webp);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .filming-header{
        /* background-image: url(../images/filming-course-header.gif);
        background-repeat:no-repeat;
        background-size: 100% 100%; */
        background-color: #a9d1e1;
    }
    .courses-header-text{
        color: navy;
        text-shadow: 1px 1px 4px #fff !important;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    .book-header{
        background-image: url(../images/book-hero-bg.png);
        background-repeat:no-repeat;
        background-size: cover;
    }
    .aerial-robotics-header{
        background-image: url(../images/webp/aerial-robotics-hero2.webp);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }

    .bootcamp-header{
        background-image: url(../images/bootcamp-header.gif);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }
    .survey-header{
        background-image: url(../images/webp/survey-header.webp);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }
    .road-survey-header{
        background-image: url(../images/webp/road-survey-header.gif);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }

    .vt-header{
        background-image: url(../images/webp/vt-header1.webp);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }
    .filming-header{
        background-image: url(../images/filming-course-header.gif);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }
    .courses-header-text{
        color: navy;
        text-shadow: 1px 1px 4px #fff !important;
        text-align: center;
        position: absolute;
        left : 15%;
        top: 50%;
    }
    .drone-build-image, .bootcamp-drone-image, .survey-vector-image, .vr-vector-image,.filming-vector-image,.book-image{
        display: none;
    }
}


