.about-component{
    margin-top: 85px;
}

.about-dsi-div{
    padding: 50px 0;
}

.about-header{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: -100;

    /* background-image: url(../images/webp/aboutHeaderImg.webp); */
    background-image: url(../images/webp/about-us-header-bg.jpg);
    background-repeat:no-repeat;
    background-size: cover;

  }

  @media only screen and (max-width: 600px) {
    .about-header{
        background-image: url(../images/webp/about-us-header-bg.jpg);
        background-repeat:no-repeat;
        background-size: cover;
    }
}

.about-header  img{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1; 
  }


  
.about-header h1{
    color:#fff;
    text-align: center;
    text-shadow: 0.08rem 0.08rem 0.8rem #000;
    text-shadow: 2px 2px 4px #000;
  }

.about-dsi-logo-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-dsi-logo{
    width: 50%;
    height: auto;
}

.about-heading-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 45px;
    margin-top: 45px;
}

.about-heading{
    /* position: relative; */
    z-index: 2;
    transition: transform .5s ease;
}

.about-overlay-img {
    position: absolute;
    left: 0;  right: 0; top:45px;
    opacity: 1;
    /* z-index: -4; */
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    height: 60px;
    transition: transform .5s ease;
  }

  .about-overlay-img:hover{
    transform: scale(1.5);
  }
  
  .about-text{
    text-align: justify;
  }

  .about-qoute{
      padding : 50px 0;
  }

  .what-we-do-div{
      padding-top : 5vh;
      padding-bottom : 80px;
  }

  .verticals-row{
      padding-top : 50px;
  }

  .verticals-icon{
    max-width: auto;
    max-height: 150px;
    transition: transform 0.5s;
    padding : 10px 0;
  }

  .verticals-component{
      padding-left: 20px;
      padding-right: 20px;
  }
  .verticals-component p{
    text-align: justify;
  }

  .verticals-component h1{
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.25rem;
  }
  .verticals-icon:hover{
      transform: scale(0.8);
  }

  .cofounders-row{
      margin-top: 5vh;
  }
  .cofounders-img{
      padding : 0 25% 10% 25% ;
  }

  .about-cofounder{
      background-color: #333333;
      color: #fff;
      padding: 30px;
  }

  .cofounder-quote{
      font-weight: 500;
      font-style: italic;
      text-align: justify;
  }