.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.active_class{
  color: orchid;
  border-bottom: px solid yellow;
}

.navbar-main-div{
  width: 100%;
  height: 70px;
}

