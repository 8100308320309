.about-component{
    margin-top: 85px;
}

.about-dsi-div{
    padding: 50px 0;
}

.flipcontainer{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.flipcontainer img{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-width: 0px 1px;
}


.about-header  img{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1; 
  }


  
.about-header h1{
    color:#fff;
    text-align: center;
    text-shadow: 0.08rem 0.08rem 0.8rem #000;
    text-shadow: 2px 2px 4px #000;
  }

.about-dsi-logo-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-dsi-logo{
    width: 50%;
    height: auto;
}

.about-heading-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 45px;
    margin-top: 45px;
}

.about-heading{
    /* position: relative; */
    z-index: 2;
    transition: transform .5s ease;
}

  .verticals-row{
      padding-top : 50px;
  }

  .details-icon{
    max-width: auto;
    max-height: 50px;
    transition: transform 0.5s;
    padding : 6px 0;
  }

  .details-component{
      padding-left: 20px;
      padding-right: 20px;
      margin: 10px 0px;
      text-align: center;
  }
  .details-header{
    margin: 0;
  }

  .verticals-component h1{
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.25rem;
  }
  .details-icon:hover{
      transform: scale(0.8);
  }

  @media only screen and (max-width: 768px) {
    .salient-drone-image{
        display: none;
    }
  }