.nav-item{
  color:rgba(0,0,0,.5);
  font-weight: 500;
  padding: 0 10px;
  list-style: none;
  text-decoration: none;
  border : 1px solid transparent;
}

.nav-item:hover{
  color: black;
  list-style: none;
  text-decoration: none;
  border : 1px solid #F49F1C;
}

.nav-contact{
  color: #fff;
  background-color:  #F49F1C;
  border-radius: 5px;
  padding: 8px 7px;
}

.nav-contact:hover{
 background-color: #282c34;
 color: #fff;
}

