.footer-two{
  background-color: #F49F1C;
}

.footer-two p{
  margin : 10px;
  padding: 0;
}

.footer-one{
  background-color: #282c34;
  padding: 20px;
}

.App-footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100vw;
  color: white;
  text-align: center;
  display: block;
}

.footer-list{
  padding : 0;
  margin: 0;
  margin-bottom: 20px;
}

.footer-li{
  list-style: none;
  color: #fff;
  text-decoration: none;
  font-size: large
}

.footer-li:hover{
  color: inherit;
}

.footer-list li{
  list-style: none;
  color: #fff;
  text-decoration: none;
}

.footer-list li a{
  color: #fff;
}

.flex-footer-list{
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flex-footer-list li {
  margin : 0 15px;
}

.flex-footer-list li a{
  font-weight: 500;
}

.footer-connect{
  margin-bottom: 30px;
}

.footer-connect span{
  font-weight: 500;
  padding-left: 8px;
  font-size: large;
} 

.social-row{
  width: 100%;
  display: block;
}
.social-container{
  align-items: center;
}
a.social {
  margin: 0 0.5rem;
  transition: transform 250ms;
  display: inline-block;
  size: 15px;
  color: #fff;
  background-color: #F49F1C;
  padding : 8px;
  border-radius: 50%;
}

a.social:hover {
  transform: translateY(-2px);
}

 a.youtube:hover {
  color: #eb3223;
}

a.facebook:hover {
  color: #3b5998;
}

a.linkedin:hover {
  color: #0e76a8;
}

a.instagram:hover {
  color: #bc2a8d;
}