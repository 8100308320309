.hero-header{
    width: 100%; 
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .content{
    /* font-size: 1.8rem; */
    line-height: 2.13;
    letter-spacing: .03rem;
    text-align: justify;
  }
  
  .hero-header video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%; 
  
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 100%;
    height: 100%;
  
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-40%);
    object-fit: cover;
  }
  .overlay {
    z-index:1;
    margin : 20px 40px;
    text-shadow: 2px 2px 4px #000000;
  }

  .overview-row,.industries-section, .drones-section , .clientelle,.vt-examples-section,.explore-gallery-section,.vt-benefits-section,.vt-highlights-component{
    margin-top: 100px;
  }

  .overview-row, .industry-card, .explore-gallery-content{
    display: flex;
    justify-content: center;
    align-items: center;
  }

.hero-img{
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: 100%;
  height: 100%;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  object-fit: cover;
}

/* .road-survey-h2{
  font-size: 0.2rem;
} */

.vt-highlights-component{
  padding: 1.2rem 0;
}

.industry-card{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  margin: 15px 0;
}
.industry-card:hover .industry-img{
  opacity: 0.5;
  background-color: #87cefa;
}

.industry-card:hover .industry-info{
  opacity: 1;
}

.industry-info{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: justify;
  width: 100%;
  padding: 2rem 2rem 0 2rem;
  font-weight: 700;
  /* color: #fff; */
  text-shadow: 4px 4px 10px #fff;
}

.survey-key-benefits div{
  padding-bottom: 0.8rem;
}

.benefits-list{
  text-align: left;
  justify-content: left;
}
.dronePhoto{
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: -18%;
  transition: transform .5s ease;
}

.drone-description{
  margin-top: 2rem;
  text-align: justify;
}

.drones-row{
  margin-top: 4rem;
}

.drones-card{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  margin: 15% 0;
}

.drones-card:hover{
  background-color: rgb(135, 206, 250,0.5);
}

.drones-card:hover .dronePhoto{
  transform: scale(1.2);
}


.explore-gallery-section img{
  width: 75%;
  height: auto;
}
.explore-gallery-section{
  text-align: left;
}

.vt-highlights-text{
  font-size: 1rem;
}
.benefits-list li{
  line-height: 2.5rem;
}

.highlights-icon{
  height: 80px;
}

.drone-specs-list li{
  font-size: 0.9rem;
}

.vt-card h2{
  font-size: 1.2rem;
}

.process-img{
  width: 90%;
  height: auto;
  }