.homeContactText{
    text-align: left;
    font-weight: 500;
    line-height: 1.39;
    letter-spacing: .1rem;
  }
.darkHeading{
    margin-top: -10px;
  }
  .lightHeading{
    opacity: 0.3;
  }

