.testimonial-main-div{
  width: 100%;
  background-color: #000;
}
.swiper-container {
  width: 100%;
  padding-bottom: 50px;  
}
.swiper-slide {
  position: relative;
  background-position: center;
  background-size: cover;
  width: 250px;
  height: 100%;
}
.testimonial-photo{
  width: 50%;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top : 0;
  left : 0;
}
.testimonial-card{
 margin-top: 100px;
}
.cardText{
  z-index: 2;
  position: relative;
}
.card-body-img {
  position: absolute;
  /* margin: auto; */
  left: 0;  right: 0; top:60%;
  width: 50%;
  height: auto;
  max-height: 100%;
  opacity: 0.3;
  z-index: -4;

  margin-left: auto;
  margin-right: auto;
  margin-top: -20%;
}
.testimonialPhoto{
  width: 40%;
  height: auto;
  border-radius: 50%;
  border: 5px solid lightblue;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20%;
}