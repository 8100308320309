.contact-main-div{
margin-top: 85px;
}

.contact-header{
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-header h1{
  text-shadow: 2px 2px 4px #000;
}

.contact-conatiner{
  width: 100%;
  background-color: aliceblue;
}
.contact-inner-div h1{
  color:#fff;
  text-align: center;
  text-shadow: 0.08rem 0.08rem 0.8rem #000;
}
.contact-header  video{
  width: 100%;
  height: 250px;
  object-fit: fill;
  position: absolute;
  z-index: -1; 
}
.contact-img{
  width: 60%;
  height: auto;
  margin-left: 70px;
}
.contact-list{
  margin-left: 90px;
}
.drone-img{
  width: 80px;
  height: auto;
  margin-top: 30px;
  }
.contact-card {
  /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
    transition: 0.3s;
    width: 80%;
    margin-top: -50px;
    opacity: 1;
    background: #fff;
    justify-content: center;
    align-items: center;
}

/* On mouse-over, add a deeper shadow */
.contact-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.contact-container {
    padding: 5px 16px;     
}

.contact-btn{
    /* background-color: #0f1ab8; */
    background-color: #0b3251;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
}
.contact-list p{
  font-size: 18px;
  font-weight: 600;
  padding-left: 8px;
}
@media only screen and (max-width: 600px) {

  .contact-card {
      margin-top: 0px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }

  .contact-list{
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  

}
