.services-row{
    margin-top: 80px;
}

.services-image{
    align-content: center;
}
.services-card{
    /* margin-left: -40%;
    margin-top: 20%; */
    margin-right: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 8px 15px 0 rgba(0,0,0,0.5);
    border-radius: 2px;
}
.services-card-title{
    font-weight: 700 ;
    font-size: 1.2rem ;
    text-transform: uppercase;
}

.services-card-text{
    opacity: 0.6;
    text-align: justify;
}

.services-card-list{
    text-align: left;
}

.services-card-list ul{
    padding-left: 0;
}
.services-card-list li{
    list-style: none;
    text-decoration: none;
    font-weight: 600;
}
.services-card-list li h2{
    font-size: 1rem;
    font-weight: bold;
}
.btn-left{
    margin-right: auto;
}
